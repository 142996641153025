<template>
  <section class="page-wrap py-5">
    <div class="container">
      <div class="row">
        <div class="inner-banner other-pg-wrapper">
          <h1>{{ title }}</h1>
        </div>
        <div class="col-12">
          <div class="boost-ads-title text-center">
            <h4>Choose Us for Rapid Sales Boost: Sell Faster Now!</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
              necessitatibus dolorem architecto dolore ad ab rem facere, ea
              doloribus praesentium hic consequatur, quos veritatis pariatur
              expedita perferendis error velit neque!
            </p>
          </div>
          <div class="boost-ads-content mt-5">
            <h4 class="text-center">How to Boost?</h4>
            <div class="method-boosts-wrapper">
              <div class="display-card">
                1. Add your product to KJ Realstate.
              </div>
              <div class="display-card">
                2. Once your product is live on KJ Realstate. Visit :
                <a class="hover-link" href="#">kjrealstate.com/boostproperty</a>
              </div>
              <div class="display-card">
                3. Select Number of days you want to Boost your product.
              </div>
              <div class="display-card">
                4. Once you know your billing fill up the form
              </div>
              <div class="display-card">5. Submit details and pay online.</div>
              <div class="display-card">
                6. We might contact you to confirm Boost and make it live.
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="boost-form-wrapper">
            <div class="page-title mt-5 mb-2"><h2>Boost your listing</h2></div>
            <div class="display-card">
              <div class="form-group mb-3">
                <label>Ads Types <span class="required-field">*</span></label>
                <select v-model="formReq.adsType" class="univ-input" @change="changeAdsType($event)">
                  <option value="">Select Ads</option>
                  <option v-for="(item,i) in adsTypes" :key="i" :value="item.id" :data-price="item.price">{{ item.title }}</option>
                </select>
              </div>
              <div class="form-group mb-3" v-if="formReq.adsType !=''">
                <label>Number of Hours <span class="required-field">*</span></label>
                <select v-model="formReq.totalTime" class="univ-input" @change="adsTimeChange" >
                  <option value="">Select Hours</option>
                  <option v-for="(hour,h) in hoursCount" :key="h">{{ hour }}</option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label>Property Lists <span class="required-field">*</span></label>
                <select v-model="formReq.propertyID" class="univ-input">
                  <option value=""> Select Property </option>
                  <option v-for="(item,j) in properties.data" :key="j" :value="item.property.id" >{{ item.property.title }}</option>
                </select>
              </div>

              <div v-if="formReq.adsType !='' && formReq.totalTime !='' && formReq.propertyID !='' " class="form-group row justify-content-center">
                <div class="col-xl-3">
                    <button class="univ-btn w-100 mt-2" @click="formSubmit" > Continue </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="boost-price-wrapper">
            <div class="page-title mt-5 mb-2"><h2>Pricing</h2></div>
            <div class="display-card">
              <table class="table-wrapper">
                <tr>
                  <th>Number of Hours</th>
                  <th>Price</th>
                </tr>
                <tr>
                  <td v-if="formReq.totalTime !=''">{{ totalTime }} Hours</td><td v-else>--</td>
                  <td v-if="formReq.totalTime !=''">Rs. {{ totalPrice }}</td><td v-else>--</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12">
            <h5 class="mt-4">Note:</h5>
            <p>Boost requests are accepted between 9AM-5PM and every request is manually monitored to assure safe community guidelines.</p>
            <p>The payments made are non-refundable. Please keep the payment receipt.</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import store from "@/store"
export default {
  name: "BoostProperty",
  data() {
    return {
      title: "Boost Property",
      userId:store.getters.user.id,
      formReq:{
        adsType:"",
        totalTime:"",
        propertyID:"",
      },
      
      totalTime:"--",
      adsPrice:0,
      totalPrice:"--",
      adsTypes: [],
      hoursCount:[],
      properties: { 
          data: [], 
      },
      formReqEsewa:{
        tAmt:10,
        amt:10,
        txAmt:0,
        psc:0,
        pdc:0,
        scd:"NP-JK-STATE",
        pid:"123456", // order id
        su:"",
        fu:"",
      },
    };
  },
  mounted() {
    let app = this;
    app.renderTypes();
    app.renderProperty();
    app.hourList();
  },
  methods:{
      async renderTypes(){
          let app = this;
          await axios.get(api.getUrl('/ads-types')).then(function (response) {
              if(response.data.success){
                  app.adsTypes = response.data.data;
              }
          }).finally(() => {
              
          });
      },
      async renderProperty(){
          let app = this;
          let url = `/user/`+app.userId+`/properties`;
          await axios.get(api.getUrl(url)).then(function (response) {
              let responseData        = response.data.data;
              if(response.data.success){
                  app.properties.data     = responseData.data;
              }
          }).finally(() => {
        
          });
      },
      hourList(){
        let app = this;
        let counter = 0;
        while( counter < 348 ){
          counter = counter + 24;
          app.hoursCount.push(counter);
        }
      },
      changeAdsType(e){
        let app = this;
        if(app.formReq.adsType!=""){
          app.adsPrice = e.target.selectedOptions[0].dataset.price;
          app.totalPrice = app.totalTime * app.adsPrice;
        }else{
          app.formReq.totalTime = "";
          app.adsPrice = 0;
          app.totalTime = "--";
          app.totalPrice = "--";
        }
      },
      adsTimeChange(){
        let app = this;
        if(app.formReq.totalTime!=""){
          app.totalTime = app.formReq.totalTime;
          app.totalPrice = app.totalTime * app.adsPrice;
        }else{
          app.totalTime = "--";
          app.totalPrice = "--";
        }
      },
      formSubmit(){
        let app = this;
        axios.post(api.getEsewaUrl('/epay/main'),app.formReqEsewa).then((response) => {
            console.log(response);
        });

      }
  }
};
</script>
